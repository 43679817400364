import { useState, useEffect, useRef } from 'react';
import { OutlinedInput, InputBase } from '@mui/material';
import AutoNumeric from 'autonumeric';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import { useAppTypeProvider } from '@ais/providers';
import {
  ClientExternalIcon,
  getTrialBalanceAmountEnding,
  isValueTrialBalanceValue
} from '@ais/forms';

const getTrialBalanceAmount = (_value) => {
  try {
    const jsonValue = JSON.parse(_value)
    return jsonValue?.amount.toFixed(2);
  } catch {
    return _value;
  }
};

export const CLACurrencyField = (props) => {
  const {
    name,
    label,
    placeholder,
    defaultValue,
    allowDecimalPadding,
    emptyInputBehavior,
    useAsDisplay,
    onChange,
    onBlur,
    notched,
    disabled,
    value,
    sx,
    onFocus,
    isInstance,
    tbLinkEnabled,
    allowExternalAccess,
    answerable,
    answered,
    field,
    tbLinkBaseField: TbLinkBaseField,
    units,
    isIdle,
    onUnlockConcurrentField
  } = props;

  const ref = useRef(null);
  const containerRef = useRef(null);
  const { isPortalApp } = useAppTypeProvider();
  const [instance, setInstance] = useState(null);

  const initAutoNumeric = (_value) => {
    const isAutoNumeric = AutoNumeric.test(instance?.domElement);
    if (!isAutoNumeric && ref.current) {
      const newInstance = new AutoNumeric(ref.current, {
        allowDecimalPadding: allowDecimalPadding || 'floats',
        emptyInputBehavior,
        currencySymbol: '$',
        decimalPlaces: 2,
        negativeBracketsTypeOnBlur: '(,)',
        selectOnFocus: false,
        showWarnings: false,
        modifyValueOnWheel: false,
        watchExternalChanges: true,
        readOnly: useAsDisplay
      });

      AutoNumeric.set(newInstance.domElement, _value)
      setInstance(newInstance);
      return;
    } else {
      instance?.set(_value);
    }
  }

  const initTBAmount = (tbAmount) => {
    if (tbAmount === 0 || tbAmount === '0.00') {
      instance?.remove();
    } else {
      initAutoNumeric(tbAmount);
    }
  };

  useEffect(() => {
    if (isIdle && containerRef.current?.classList.contains('Mui-focused')) {
        initAutoNumeric(defaultValue); 
        containerRef.current?.classList.remove('Mui-focused');
        ref.current?.blur();
    }
}, [isIdle, defaultValue])

  useEffect(() => {

    if (!isNaN(defaultValue) || defaultValue === '' || defaultValue === '$') {
      initAutoNumeric(defaultValue);
      return;
    }

    const isTrialBalanceValue = isValueTrialBalanceValue(defaultValue);

    if (isTrialBalanceValue && isPortalApp) {
      const tbAmount = getTrialBalanceAmount(defaultValue);
      initTBAmount(+tbAmount);
    } else if (isTrialBalanceValue && !isPortalApp) {
      const tbAmountEnding = getTrialBalanceAmountEnding(defaultValue);
      initTBAmount(tbAmountEnding);
    } else {
      initAutoNumeric(defaultValue)
    }
  }, [defaultValue, ref.current]);

  if (useAsDisplay)
    return <InputBase inputRef={ref} placeholder={placeholder} readOnly />;

  if (tbLinkEnabled && TbLinkBaseField && isPortalApp) {
    return (
      <TbLinkBaseField
        inputRef={ref}
        sx={sx}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => onChange && onChange(null, e)}
        onBlur={(e) => onBlur && onBlur(null, e)}
        onFocus={onFocus}
        notched={notched}
        disabled={disabled}
        isFormInstance={isInstance}
        tbLinkEnabled={isInstance}
        answered={answered}
        field={field}
        units={units}
        isIdle={isIdle}
        onDrawerOpen={onFocus}
        onUnlockConcurrentField={onUnlockConcurrentField}
      />
    )
  }

  return (
    <OutlinedInput
      inputRef={ref}
      ref={containerRef}
      sx={sx}
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange && onChange(e, instance?.getNumericString())}
      onBlur={(e) => onBlur && onBlur(e, instance?.getNumericString())}
      onFocus={onFocus}
      notched={notched}
      disabled={disabled}
      endAdornment={
        <InputAdornment position="end">
          <ClientExternalIcon
            allowExternalAccess={allowExternalAccess}
            answerable={answerable}
            answered={answered} />
        </InputAdornment>}
    />
  );
};

CLACurrencyField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowDecimalPadding: PropTypes.bool,
  emptyInputBehavior: PropTypes.string,
  useAsDisplay: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  notched: PropTypes.bool,
  disabled: PropTypes.bool,
  allowExternalAccess: PropTypes.bool,
  answerable: PropTypes.bool
};

CLACurrencyField.defaultProps = {
  allowDecimalPadding: false,
  emptyInputBehavior: 'focus',
  useAsDisplay: false,
  notched: false,
  disabled: false,
  allowExternalAccess: false,
  answerable: false
};

export default CLACurrencyField;
