import { useCallback, useContext, useState } from 'react';
import { InternalControlContext } from '@components/CustomForm/InternalControl/UnderstandingOfEntity/context/InternalControlContext';

export const useICF = () => {
    const [currentInternalControlData, setCurrentInternalControlData] = useState(null);

    const {
        internalControlData,
        setInternalControlData,
        concurrencyEventReceived,
        setConcurrencyEventReceived
    } = useContext(InternalControlContext);

    const handleInternalControlDataChanged = useCallback((data) => {
        const { code, ...rest } = data;  
        switch (code) {
            case 'whole':
                setCurrentInternalControlData(rest)
                break;
            case 'updated-properties': 
                setCurrentInternalControlData((prev) => ({
                    ...prev,
                    ...rest
                }));
                break;
            case 'ICInfoProcessingAndControlActivity':  
                const { ICInfoProcessingAndControlActivity, ProjectScopeAuditAreaSCOTABDId } = rest;
                const projectScopeAuditArea = Object.assign(currentInternalControlData.ProjectScopeAuditArea, [])
                projectScopeAuditArea.forEach((projectScopeAuditArea) => {
                    if (projectScopeAuditArea.ProjectScopeAuditAreaSCOTABDS) {
                        projectScopeAuditArea.ProjectScopeAuditAreaSCOTABDS.forEach((psaas) => {
                            if (psaas.ProjectScopeAuditAreaSCOTABDId === parseInt(ProjectScopeAuditAreaSCOTABDId)) { 
                                psaas.ICInfoProcessingAndControlActivity = ICInfoProcessingAndControlActivity
                            }
                        })
                    }
                })   
                setCurrentInternalControlData(prev => ({
                    ...prev,
                    ProjectScopeAuditArea: projectScopeAuditArea
                }));
                break;
            default:
                break;
        } 
    }, [currentInternalControlData])

    return {
        internalControlData,
        setInternalControlData,
        concurrencyEventReceived,
        setConcurrencyEventReceived,
        handleInternalControlDataChanged,
        currentInternalControlData,
        setCurrentInternalControlData
    }
}

