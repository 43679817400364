import { createContext, useContext, useMemo, useState, useRef, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useQuestionsWithLink, useSubmitFormMutation } from "@services";
import { QUESTION_LINK_TYPE } from "@ais/constants"
import { useFormContext } from "react-hook-form";
import { transformAnswers } from '@ais/utilities';
const ProjectFormContext = createContext()

export const ProjectFormContextProvider = ({ projectForm, answers = {}, children }) => { 
    const { projectId, projectFormId } = useParams();
    const { reset } = useFormContext();
    const { data: linkedQuestions } = useQuestionsWithLink(projectId, projectFormId);
    const { mutateAsync: submitFormMutate } = useSubmitFormMutation(projectFormId, projectId);
    const [isQuestionGroupFocused, setIsQuestionGroupFocused] = useState(false);
    const [focusedId, setFocusedId] = useState(null)
    const [answerList, setAnswerList] = useState(answers)
    const fieldRef = useRef(null);
    const linkDefaultList = useMemo(() => {
        if(!projectForm) {
            return []
        }
        const list = projectForm.schema.flatMap(section => section)?.flatMap(row => row.fields)?.flatMap(field => field)?.flatMap(field => (
            {
              id: field.id,
              isUserModified: !!field.isUserModified,
              existingQuestionId: field.existingQuestionId,
              linkType: field.linkType
            }
        ))?.filter(field => field.linkType === QUESTION_LINK_TYPE.QUESTION_LINK_TYPES[2])
        return list
    }, [projectForm]);

    const submitForm = async () => {
        await submitFormMutate();
    }

    const handleUpdateIsQuestionGroupFocused = (isQuestionGroupFocused) => {
        setIsQuestionGroupFocused(isQuestionGroupFocused);
      };

    const handleFocusField = (e, id) => {
        if (e) fieldRef.current = e.target;
        setFocusedId(id);
    }

    useEffect(() => { 
        if (Object.keys(answerList).length > 0) {
            reset(answerList);
        }
    }, [answerList])
    
    useEffect(() => {
        if (Object.keys(answers).length > 0) {
            setAnswerList(answers)
        } 
    }, [answers])
    return (
        <ProjectFormContext.Provider value={{
            projectId,
            projectFormId,
            projectForm,
            answers,
            linkedQuestions,
            linkDefaultList,
            isQuestionGroupFocused,
            focusedId,
            fieldRef,
            actions: {
                submitForm,
                updateIsQuestionGroupFocused: handleUpdateIsQuestionGroupFocused,
                onFocus: handleFocusField,
                onUpdateEvent: setAnswerList
            }
        }}>
            { children }
        </ProjectFormContext.Provider>
    )
}

export const useProjectFormContext = () => {
    const ctx = useContext(ProjectFormContext)
    if(!ctx) {
        throw new Error("`useProjectFormContext` must be used within ProjectFormContextProvider")
    }
    return ctx
}
