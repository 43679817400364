import { useState, useEffect, useRef } from 'react'; 
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import PropTypes from 'prop-types';
import { DateIcon, DateIconDisabled } from "@ais/assets";
import {ClientExternalIcon} from '@ais/forms';
import { TextField, InputAdornment } from '@mui/material';

export const CLADatePickerField = (props) => {
	const {
		defaultValue,
		label,
		placeholder,
		disablePast,
		minDate,
		disabled,
		onChange,
		onBlur,
		onFocus,
		allowMultiLineLabel,
		isError,
		allowExternalAccess,
		answerable,
		answered,
		textVariant,
		disableUnderline,
		readOnly,
		textFieldStyle,
		textFieldSx,
		isIdle,
		isQuestionGroup,
		extraShrinkedInputLabelProps
	} = props;
	const [value, setValue] = useState(defaultValue);
	const [errorState, setErrorState] = useState('');
	const [open, setOpen] = useState(false);
	const containerRef = useRef(null);
	const isValidDate = (d) => {
		return d instanceof Date && !isNaN(d);
	}

	useEffect(() => {
		if (value !== defaultValue) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (isIdle) {
			containerRef.current?.classList.remove('Mui-focused'); 
			setOpen(false)
		}
	}, [isIdle])

	const dateIcon = () => {
		return (!disabled) ? (
			<img src={DateIcon} style={{ "marginRight": "5px" }} alt='CLA Date icon' />
			) :
			( <img src={DateIconDisabled} style={{ "marginRight": "5px" }} alt='CLA Date icon' /> )
	}

	const handleOnChange = (e, isFromOnAccept) => {
		let updatedDate = e?.toLocaleDateString('en-US', 
		{
			month: '2-digit',
			day: '2-digit',
			year: 'numeric'
		}) ?? null;
		const isValid = e === null ? true : isValidDate(e);
		if (isValid) {
			setValue(updatedDate);
			setErrorState('');
		} else {
			updatedDate = null;
			setErrorState('Invalid Date Format');
		}

		if (onChange) {
			onChange(updatedDate);
		}

		if (isFromOnAccept && onBlur && isQuestionGroup) {
			onBlur(updatedDate, true)
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DatePicker
				data-testid="date-picker"
				PopperProps={{
					placement: 'bottom-end'
				}}
				ref={containerRef}
				value={value}
				disablePast={disablePast}
				minDate={minDate}
				onChange={e => {handleOnChange(e)}}
				onAccept={e => {handleOnChange(e, true)}}
				onClose={() => { 
					onBlur && onBlur(value)
					setOpen(false)
				}} 
				onOpen={() => {   
					onFocus && onFocus()
					setOpen(true)
				}}
				open={open}
				onError={(params) => {
					if (params === 'maxDate') setErrorState('Invalid Date Format')
					else setErrorState('')
				}}
				components={{
					OpenPickerIcon: dateIcon
				}}
				renderInput={(props) => {
					if (placeholder) props.inputProps.placeholder = placeholder;
					props.InputProps.notched = true;
					return (
						<TextField
							{...props}
							label={label}
							helperText={errorState}
							error={!!errorState || isError}
							InputLabelProps={{
								shrink: true,
								...(allowMultiLineLabel ? { variant: 'multiline-label' } : (disabled ? {...extraShrinkedInputLabelProps} : {}))
							}} 
							InputProps={{
								...props.InputProps,
								endAdornment: (
									<>
										{props.InputProps.endAdornment}
										<InputAdornment position="end">
											<ClientExternalIcon
												allowExternalAccess={allowExternalAccess}
												answerable={answerable}
												answered={answered}
											/>
										</InputAdornment>
									</>
								),
								disableUnderline,
							}}
							inputProps={{
								...props.inputProps,
								readOnly,
								style: textFieldStyle,
								sx: textFieldSx
							}}
							FormHelperTextProps={{
								sx: {
									mx: 0,
									marginBottom: 1,
									'&::after': {
										content: '""',
										display: 'block',
										height: 60,
										marginTop: -60,
										fontSize: '0.5rem'
									}
								}
							}}
							fullWidth
							onBlur={() => {onBlur && onBlur(value)}}
							onFocus={onFocus}
							variant={textVariant}
						/>
					);
				}}
				disabled={disabled}
			/>
		</LocalizationProvider>
	);
};

CLADatePickerField.propTypes = {
	defaultValue: PropTypes.any,
	label: PropTypes.node,
	placeholder: PropTypes.string,
	disablePast: PropTypes.bool,
	minDate: PropTypes.any,
	disabled: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	allowMultiLineLabel: PropTypes.bool,
	isError: PropTypes.bool,
	allowExternalAccess: PropTypes.bool,
	answerable: PropTypes.bool,
	textVariant: PropTypes.string,
	disableUnderline: PropTypes.bool,
	readOnly: PropTypes.bool,
	textFieldStyle: PropTypes.any,
	textFieldSx: PropTypes.any
};

CLADatePickerField.defaultProps = {
	defaultValue: null,
	disablePast: false,
	disabled: false,
	allowMultiLineLabel: false,
	isError: false,
	allowExternalAccess: false,
	answerable: false,
	disableUnderline: false,
	readOnly: false
};

export default CLADatePickerField;
