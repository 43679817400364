import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    Grid,
} from '@mui/material';
import {
    renderLabel
} from '@ais/forms';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Flex } from '@ais/palette';

const RadioButtonList = (props) => {
    const {
        tooltip = '',
        columns,
        options,
        label,
        id,
        disabled,
        onChange,
        value
    } = props;

    const { control } = useFormContext();

    return (
        <FormControl style={{ width: '100%' }}>
            <Flex direction='row' justify='between'>
                <FormLabel id={id} style={{ marginBottom: '12px' }}>
                    {renderLabel(label, tooltip)}
                </FormLabel>
            </Flex>
            <Grid container spacing={2} columns={columns}>
                {options.map((val) => (
                    <Controller
                        key={val}
                        name={id}
                        control={control}
                        render={({ field }) => (
                            <Grid item xs={1}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            {...field}
                                            checked={value === val}
                                            onChange={onChange}
                                            inputProps={{
                                                'test-id': `RadioButton-${id}`,
                                                'test-value': value
                                            }}
                                            value={val}
                                            disabled={disabled}
                                        />
                                    }
                                    label={val}
                                    style={{ minWidth: '150px' }}
                                />
                            </Grid>
                        )}

                    />

                ))}
            </Grid>
        </FormControl>
    )
}
export default React.memo(RadioButtonList);