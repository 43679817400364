import { useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import HelpIcon from "@mui/icons-material/Help"
import { Collapse, IconButton } from "@mui/material"
import { sanitize } from "dompurify"
import { useRules } from '@hooks/useRules';
import { CollapseIconComponent, ExpandIconComponent } from "@ais/assets";
import { CLATooltip } from "@ais/components"

import styles from "./Section.module.css"

const SECTION_TYPES = [
    'SECTION',
    'CUSTOM',
    'PROCEDURE',
    'SEMICUSTOM'
];

export const useScroll = () => {
    const elRef = useRef(null);
    const executeScroll = () => elRef.current.scrollIntoView();

    return [executeScroll, elRef];
};

export const Section = ({ section, children }) => {
    const { shouldDisplay } = useRules(section.rules);

    const [expanded, setExpanded] = useState(!section.showCollapsed)
    const { search } = useLocation()
    const [executeScroll, elRef] = useScroll()
    const searchParams = new URLSearchParams(search)
    const questionIds = searchParams.get("questionIds")
    const sectionId = searchParams.get("sectionId")
    const sectionType = section ? (section.sectionType ? section.sectionType : section.type) : null
    const hasSingleQuestion = !questionIds || questionIds.split(',').length <= 1
    const shouldScrollToSection = !sectionId ? false : (section.id.toLowerCase().trim() === sectionId.toLowerCase().trim() && hasSingleQuestion)
    const sectionCSS = sectionType === 'SECTION_BLANK' ? styles['vf-section-blank'] : ''

    useEffect(() => {
        if (shouldScrollToSection) {
            executeScroll()
        }
    }, [])

    return (
        <div className={styles["vf-section-wrapper"]} style={{ display: !shouldDisplay ? 'none' : 'flex' }}>
            <div ref={elRef} className={`${styles['vf-section']} ${sectionCSS}`}>
                {(SECTION_TYPES.includes(sectionType)) && (
                    <div className={styles['section-header-wrapper']}>
                        <IconButton onClick={() => setExpanded((oldState) => !oldState)}>
                            {expanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                        </IconButton>
                        <div className={styles["section-header"]}>
                            {section.title}
                            {section.tooltip && (
                                <CLATooltip
                                    title={
                                        <div
                                            className="ql-editor"
                                            dangerouslySetInnerHTML={{
                                                __html: sanitize(section.tooltip, {
                                                    ADD_ATTR: ['target'],
                                                }),
                                            }}
                                        >
                                        </div>
                                    }
                                    placement="bottom"
                                >
                                    <HelpIcon style={{ fontSize: '18px' }} />
                                </CLATooltip>
                            )}
                        </div>
                    </div>
                )}
                {section.description && (
                    <div style={{ fontSize: '14px', color: '#595959' }}>
                        <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                                __html: sanitize(section.description, {
                                    ADD_ATTR: ['target'],
                                }),
                            }}
                        />
                    </div>
                )}
                <Collapse in={expanded} collapsedSize={0}>
                    <div className={styles['vf-section-inner']}>
                        {children}
                    </div>
                </Collapse>
            </div>
        </div >
    )
}

export default Section;
