import { useState, useEffect, useRef } from 'react';
import { OutlinedInput } from '@mui/material';
import AutoNumeric from 'autonumeric';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import { ClientExternalIcon } from '@ais/forms';

const isFalsy = (value) => {
  return value === null || value === undefined || value === '';
};

export const CLAPercentageField = (props) => {
  const {
    name,
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    notched,
    disabled,
    sx,
    onFocus,
    useAsDisplay,
    allowExternalAccess,
    answerable,
    answered,
    isIdle,
    isRowTable = false
  } = props;

  const ref = useRef(null);
  const containerRef = useRef(null);
  const positionsRef = useRef({
    start: 0,
    end: 0
  });
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    if (isIdle && containerRef.current?.classList.contains('Mui-focused')) {
      const _value = isFalsy(value) ? '' : Number(value) * 100;
      const fixedDecimalsValue = parseFloat(_value).toFixed(2);
      instance?.set(fixedDecimalsValue);
      containerRef.current?.classList.remove('Mui-focused');
      ref.current?.blur();
    }
  }, [isIdle, value])

  useEffect(() => {
    if (ref.current) {
      const newInstance = new AutoNumeric(ref.current, {
        allowDecimalPadding: true,
        digitGroupSeparator: '',
        modifyValueOnWheel: false,
        selectOnFocus: false,
        showWarnings: false,
        suffixText: '%',
        watchExternalChanges: true,
      });
      newInstance.set(isFalsy(value) ? '' : Number(value) * 100);
      setInstance(newInstance);
    }
  }, []);

  useEffect(() => {
    if (!isRowTable) {
      const _value = isFalsy(value) ? '' : Number(value) * 100;
      const fixedDecimalsValue = parseFloat(_value).toFixed(2);
      if (useAsDisplay) instance?.set(_value);
      else {
        if (isFalsy(value)) instance?.set(_value);
        else {
          instance?.setUnformatted(fixedDecimalsValue);
          if (ref.current) ref.current.setSelectionRange(positionsRef.current.start, positionsRef.current.end);
        }
      }
    }
  }, [useAsDisplay, value]);

  return (
    <OutlinedInput
      inputRef={ref}
      ref={containerRef}
      sx={sx}
      name={name}
      label={label}
      defaultValue={value}
      placeholder={placeholder}
      onChange={(e) => {
        const cursorStart = e.target.selectionStart;
        const cursorEnd = e.target.selectionEnd;
        positionsRef.current.start = cursorStart;
        positionsRef.current.end = cursorEnd;
        if (e.target.value === '' || e.target.value === '%') {
          onChange && onChange(e, '');
        } else {
          onChange && onChange(e, Number(instance?.getNumericString()) / 100);
        }
      }}
      onBlur={(e) => onBlur && onBlur(e, Number(instance?.getNumericString()) / 100)}
      notched={notched}
      disabled={disabled}
      onFocus={onFocus && onFocus}
      endAdornment={
        <InputAdornment position="end">
          <ClientExternalIcon
            allowExternalAccess={allowExternalAccess}
            answerable={answerable}
            answered={answered} />
        </InputAdornment>}
    />
  );
};

CLAPercentageField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  notched: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  onFocus: PropTypes.func,
  useAsDisplay: PropTypes.bool,
  allowExternalAccess: PropTypes.bool,
  answerable: PropTypes.bool
};

CLAPercentageField.defaultProps = {
  notched: false,
  disabled: false,
  useAsDisplay: false,
  allowExternalAccess: false,
  answerable: false
};

export default CLAPercentageField;
