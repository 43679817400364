/*
This is a mock service that exists solely for ext-portal import resolution on craco evaluation when building the ext-portal
The ext-portal does not use the TBLink feature by design, so this service is not used in the ext-portal application
*/
export const useProjectTrialBalances = () => {
    return [];
};

export const useTrialBalances = () => {
    return [];
};