import { memo } from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/system';
import { Controller, useFormContext } from 'react-hook-form';

import { renderLabel } from '@ais/forms';
import { useRoomIdle } from '@ais/hooks';
import { useAppTypeProvider } from '@ais/providers';

import TbLink from '@ais/forms/src/V2/FieldComponents/TbLink/TbLink';
import { GetAnswerFromTrialBalanceSupportingValue, IsAnswerTrialBalance } from '@ais/forms/src/V2/FieldComponents/TbLink/TbLinkHelper';

const StyledInputAdornment = styled(InputAdornment)({
  marginTop: '12px',
  marginRight: '-5px'
});

const StyledOutlinedInput = styled(OutlinedInput)({
  alignItems: 'flex-start'
});

const LongAnswer = (
  {
    field: {
      id,
      minRows,
      label,
      tooltip,
      placeholder
    },
    disabled,
    clientExternalIcon,
    lockField,
    unlockField,
    onSubmit,
    onFocus,
    project
  }) => {
  const { control } = useFormContext();
  const renderedLabel = renderLabel(label, tooltip, null);
  const LONG_ANSWER_MAX_CHARACTERS = 4096;
  const isIdle = useRoomIdle();
  const { isPortalApp } = useAppTypeProvider();

  const handleBlur = () => {
    if (isIdle) return
    onSubmit(id);
    unlockField && unlockField();
  }
  const handleFocus = (e) => {
    onFocus(e, id);
    lockField && lockField();
  }

  return (
    <FormControl fullWidth>
      <InputLabel shrink variant="multiline-label">
        {renderedLabel}
      </InputLabel>
      <Controller
        key={id}
        control={control}
        name={id}
        render={({ field: { value, onChange } }) => (
          <StyledOutlinedInput
            multiline
            notched
            maxRows={20}
            minRows={minRows}
            label={renderedLabel}
            placeholder={placeholder}
            inputProps={{
              maxLength: LONG_ANSWER_MAX_CHARACTERS,
              'test-id': `LongAnswer-${id}`,
              'test-value': GetAnswerFromTrialBalanceSupportingValue(value)
            }}
            endAdornment={
              <StyledInputAdornment position="end">
                {isPortalApp &&
                  <TbLink
                    value={value}
                    id={id}
                    handleBlur={handleBlur}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    onFocus={onFocus}
                    project={project}
                    disabled={disabled}
                    lockField={lockField}
                    unlockField={unlockField}
                    isIdle={isIdle}
                  />}
                {clientExternalIcon}
              </StyledInputAdornment>
            }
            value={GetAnswerFromTrialBalanceSupportingValue(value)}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            readOnly={IsAnswerTrialBalance(value) && isPortalApp}
          />
        )}
      />
    </FormControl>
  );
}

export default memo(LongAnswer);
