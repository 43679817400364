import React, { useState } from 'react';
import { Typography, Avatar } from '@mui/material';
import clsx from "clsx";

import styles from "@ais/forms/src/V2/FieldComponents/TbLink/TbLink.module.css";
import TbLinkDrawer from '@ais/forms/src/V2/FieldComponents/TbLink/TbLinkDrawer';
import { IsAnswerTrialBalance } from "@ais/forms/src/V2/FieldComponents/TbLink/TbLinkHelper";

const CheckIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" {...props}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>

    )
}

const TbLink = (props) => {
    const { value, id, handleBlur, onChange, project, onSubmit, onFocus, disabled, lockField, unlockField, isIdle } = props;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isTbLinked = IsAnswerTrialBalance(value);

    const componentClassName = clsx(
        styles["tblink"],
        isTbLinked && styles["tblink--valid"],
        !isTbLinked && styles["tblink--invalid"]
    );

    const onTbLinkClicked = () => {
        setDrawerOpen((prevValue) => !prevValue);
        console.log('TbLink clicked, drawerOpen:', drawerOpen);
        lockField && lockField();
    }

    return (
        <>
            {isTbLinked ? (
                <span className={componentClassName} onClick={onTbLinkClicked}>
                    <Avatar component='span'
                        target='blank'
                        style={{ backgroundColor: 'white', color: "inherit", marginRight: '7px' }}
                    >
                        <CheckIcon className={styles["tblink__check-icon"]} />
                        <Typography
                            variant="subtitle2"
                            sx={{
                                fontWeight: 'bold'
                            }}>
                            {"TB"}
                        </Typography>
                    </Avatar>
                </span>
            ) : (
                <span className={componentClassName} onClick={onTbLinkClicked}>
                    <Avatar component='span'
                        target='blank'
                        style={{ backgroundColor: 'white', color: 'hsl(181deg 37% 51%)', width: '30px' }}
                    >
                        {isTbLinked && <CheckIcon className={styles["tblink__check-icon"]} />}
                        <Typography
                            variant="subtitle2"
                            sx={{
                                fontWeight: 'bold',
                                textDecoration: 'underline hsl(181deg 37% 51%)'
                            }}>
                            {"TB"}
                        </Typography>
                    </Avatar>
                </span>
            )}
            {drawerOpen && (
                <TbLinkDrawer
                    value={value}
                    open={drawerOpen}
                    setOpen={setDrawerOpen}
                    props={props}
                    id={id}
                    handleBlur={handleBlur}
                    onChange={onChange}
                    project={project}
                    onSubmit={onSubmit}
                    onFocus={onFocus}
                    isDisabled={disabled}
                    unlockField={unlockField}
                    isIdle={isIdle}
                />
            )}
        </>
    );
}
export default TbLink;