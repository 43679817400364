import React, {useState, useMemo} from 'react';
import {styled} from '@mui/system';

import {LinkIconComponent} from '@ais/assets';

import {useProjectFormContext} from '@contexts';

import {LinkPanel} from '../LinkPanel';

const StyledDiv = styled('div')({
	cursor: 'pointer',
	margin: '5px 0 0 5px',
	alignSelf: 'center'
});

export const Link = ({questionId}) => {
	const [linkedFieldsShown, setLinkedFieldsShown] = useState(false);
	const {linkedQuestions} = useProjectFormContext();
	
	const hasLinkedQuestions = useMemo(() => {
		return linkedQuestions ? linkedQuestions.includes(questionId) : false
	}, [linkedQuestions]);

	const handleClose = () => {
		setLinkedFieldsShown(false);
	};

	if (!hasLinkedQuestions) return <></>;

  return (
    <StyledDiv>
      <LinkIconComponent onClick={() => setLinkedFieldsShown((prev) => !prev)} />
      {linkedFieldsShown && <LinkPanel open={linkedFieldsShown} onClose={handleClose} questionId={questionId} disabled />}
    </StyledDiv>
  );
};
