import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { evaluateFormula } from '@ais/utilities';
import { CLATooltip } from "@ais/components"
import { colors } from '@ais/theme';
import styles from '../FormView/FormView.module.css';
import { isValueTrialBalanceValue, renderLabel } from '@ais/forms';

const Formula = (props) => {
  const { field, clientExternalIcon, answerList } = props;

  const [formulaDisplay, setFormulaDisplay] = useState('');
  const [formulaResult, setFormulaResult] = useState(null);
  const [hasMissingInput, setHasMissingInput] = useState(false);

  const getNumberFormat = (type, decimalPlaces) => {
    let format;
    switch (type) {
      case 'usCurrency':
        format = Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        });
        break;
      case 'percentage':
        format = Intl.NumberFormat('en-US', {
          style: 'percent',
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        });
        break;
      default:
        format = Intl.NumberFormat('en-US', {
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        });
    }
    return format;
  };

  const evaluatesFormula = (formula, isMissing) => {
    try {
      setHasMissingInput(isMissing);
      if (isMissing) {
        throw new Error('Missing input');
      }
      const result = evaluateFormula(formula);
      if (result === undefined || result === null || result === 'Infinity' || isNaN(result)){
        throw new Error('Invalid');
      }
      const numberFormat = getNumberFormat(
        field.validation,
        field.decimalPlaces
      );
      let resultInText = numberFormat.format(result);
      setFormulaResult({ value: resultInText, success: true });
    } catch (e) {
      if (e.message === 'Missing input')
        setFormulaResult({
          value: 'Missing input',
          success: false,
        });
      else setFormulaResult({ value: 'Invalid Formula', success: false });
    }
  };

  useEffect(() => {
    let formulaText = '';
    let isMissing = false;
    const formula = field.formula
      ?.map((item) => {
        formulaText = `${formulaText} ${item.value}`;
        if (item.itemType === 'FORMULA_QUESTION') {
          const answer =
            answerList && answerList[item.questionId]
              ? answerList[item.questionId]
              : '0';
          if (!answerList || !answerList[item.questionId] || answerList[item.questionId] === '%') isMissing = true;
          if (!isMissing) {
            if (isValueTrialBalanceValue(answerList[item.questionId])) {
              return JSON.parse(answerList[item.questionId]).amountEnding;
            }
          }
          //could be string or number
          if (typeof answer === 'string') {
            //Is a string, format to number
            if (answer?.indexOf('$') >= 0) {
              return +answer.replace('$', '').replaceAll(',', '');
            } else if (answer.endsWith('%')) {
              return +answer.slice(0, -1).replaceAll(',', '') / 100;
            } else {
              return +answer.replaceAll(',', '');
            }
          } else {
            //Is a number, already formatted
            return answer;
          }
        } else return item.value;
      })
      .join(' ');
    evaluatesFormula(formula, isMissing);
    setFormulaDisplay(`= ${formulaText}`);
  }, [field, answerList]);

  const displayResult = (
    <span
      className={styles['vf-section-formula-value']}
      style={{
        color: !formulaResult?.success ? colors.red[200] : colors.navy[400],
        fontWeight: 'normal',
      }}
    >
      {formulaResult?.value}
    </span>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={styles['vf-section-formula-label']} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
        { renderLabel(field.label, field.tooltip)}
        </div>
        { clientExternalIcon }
      </Grid>
      <Grid item xs={12} test-id={`Formula-${field.id}`} test-value={field.value}>
        {field.displayFormula && (
          <>
            {hasMissingInput && (
              <CLATooltip title="Formula cannot be rendered due to missing input">
                <ErrorIcon
                  style={{
                    color: colors.red[200],
                    fontSize: '20px',
                    marginRight: '8px',
                  }}
                />
              </CLATooltip>
            )}
            <CLATooltip
              title={<div style={{ fontSize: '16px' }}>{formulaDisplay}</div>}
              placement="top"
            >
              {displayResult}
            </CLATooltip>
          </>
        )}
        {!field.displayFormula && displayResult}
      </Grid>
    </Grid>
  );

};

export default React.memo(Formula);
