import { Section, FieldBuilder } from "@components/ProjectForm";

export const FormSection = ({ schema, handleSubmit }) => {
    return (
        <>
            {schema?.map((element) => {
                switch (element.type) {
                    case 'SECTION':
                    case 'SEMICUSTOM':
                        return (
                            <Section key={element.id} section={element}>
                                <FieldBuilder handleSubmit={handleSubmit} fields={element.fields} />
                            </Section>
                        )
                    default: {
                        return null
                    }
                }
            })}

        </>
    )
}

export default FormSection;
