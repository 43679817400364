import React from 'react'
import { IconButton, MenuItem, TableCell } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CLAActionEllipsis } from '@ais/forms'
import { CLA_FORMTABLE } from '@ais/constants';
import { v4 as uuidv4 } from 'uuid';
const FORMTABLE_LABEL = CLA_FORMTABLE.EN;

const TableActionButton = ({tableData, rowData, disabled, index, onFocus, onClose, fieldArrayMethods : { append, insert, remove, fields}, onSubmit}) => {
  const { columns, id } = tableData
  
  const fieldIds = columns.reduce((acc, curr) => {
    let value = ''
    if(curr.columnFieldType === 'checkbox') value = curr.checkboxCheckedByDefault
    if(curr.columnFieldType === 'radioButton') value = curr.radioDefaultValue
    if(curr.columnFieldType === 'dropdown') value = curr.dropdownDefaultValue
    acc[curr.id] = value
    return acc
  },{})

  const menuItem = [
    {
      label: FORMTABLE_LABEL.INSERT_ABOVE,
      key: `insertRowAbove${index}${id}`,
      onClick: () => {
        insert(index === 0 ? 0 : index, {
          rowLabel: '',
          rowId: uuidv4(),
          ...fieldIds
        })
        onSubmit(id)
      }
    },
    {
      label: FORMTABLE_LABEL.INSERT_BELOW,
      key: `insertRowBelow${index}${id}`,
      onClick: () => {
        insert(index + 1, {
          rowLabel: '',
          rowId: uuidv4(),
          ...fieldIds
        })
        onSubmit(id)
      }
    },
    {
      label: FORMTABLE_LABEL.REMOVE,
      key: `removeRow${index}${id}`,
      disabled: fields.length === 1,
      onClick:()=>{
        remove(index)
        onSubmit(id)
      }
    },
    {
      label: FORMTABLE_LABEL.DUPLICATE,
      key: `duplicateRow${index}${id}`,
      onClick: ()=> {
        append({
          ...rowData,
          rowId: uuidv4()
        })
        onSubmit(id)
      }
    }
  ]

  if(disabled) return (
    <TableCell>
      <IconButton
        aria-label="action-ellipsis"
        className="action-ellipsis"
        data-testid="action-ellipsis"
        sx={{ pointerEvents: 'none', cursor: 'not-allowed' }}
      >
        <MoreVertIcon/>
      </IconButton>
    </TableCell>
  )

  return (
    <TableCell>
      <CLAActionEllipsis autofocus={false} onFocus={onFocus} onClose={onClose}>
        {handleClose => 
          menuItem.map(item => (
            <MenuItem
              key={item.key}
              variant="ellipsis-menu"
              disabled={item.disabled ?? false}
              onClick={() => {
                item.onClick()
                handleClose()
              }}
            >
              {item.label}
            </MenuItem>
          ))
        }
      </CLAActionEllipsis>
    </TableCell>
  )

}

export default React.memo(TableActionButton)