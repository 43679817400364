import { memo } from "react";
import { Select, MenuItem } from '@mui/material'

const Dropdown = (props) => {

  return (
    <Select
      sx={{ width: '100%' }}
      notched
      displayEmpty
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      inputProps={props.inputProps}
      onOpen={props.onFocus}
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
      renderValue={(selected) => { 
        if (!selected) {
          return <span style={{ color: '#cbcbcb' }}>Select</span>;
        }

        return selected;
      }}
    >
      <MenuItem disabled value="">
        Select
      </MenuItem>
      {
        props.options.map((option) => (
          <MenuItem
            key={option}
            disabled={props.disabled}
            value={option}
          >
            {option}
          </MenuItem>
        ))
      }
    </Select>
  )}

export default memo(Dropdown)