import React, { useEffect, useState } from 'react';
import { OutlinedInput, InputLabel, MenuItem, FormControl, ListItemText, InputAdornment, Select, Checkbox } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useRoomIdle } from '@ais/hooks';
import classes from './CLASelect.module.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(_ => ({
  selectIcon: {
    position: "relative"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CLASelect = (props) => {
  const {
    menuItems,
    allowExternalAccess,
    label,
    id,
    error,
    placeholder,
    name,
    isDisabled,
    clientExternalIcon,
    lockField,
    unlockField,
    onFocus,
    onSubmit,
    open = false
  } = props;
  const classesStyles = useStyles();
  const isIdle = useRoomIdle();

  const [_open, setOpen] = useState(open)
  const { control } = useFormContext();

  const submit = () => {
    unlockField && unlockField();
    setOpen(false)
    onSubmit(id);
  }

  const handleOpen = () => {
    onFocus(null, id)
    setOpen(true)
    lockField && lockField();
  }

  const fieldId = "select-" + id;

  useEffect(() => {
    if (isIdle) setOpen(false)
  }, [isIdle])

  return (
    <FormControl className={classes['cla-select']} error={error} fullWidth>
      <InputLabel shrink id={id} variant="multiline-label">{label}</InputLabel>
      <Controller
        key={id}
        control={control}
        name={id}
        render={({ field: { value, onChange } }) => (
          <Select
            labelId={`${fieldId}-label`}
            id={fieldId}
            classes={{
              icon: classesStyles.selectIcon
            }}
            open={_open}
            sx={{ paddingRight: '4px' }}
            test-id={fieldId}
            test-value={value}
            name={name}
            disabled={isDisabled}
            multiple
            displayEmpty={true}
            value={value ? [...value] : []}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              onChange(typeof value === 'string' ? value.split(',') : value);
            }}
            onOpen={handleOpen}
            onClose={submit}
            input={<OutlinedInput label={label} />}
            renderValue={(values) => {
              const displayValue = values.length > 0 ?
                menuItems.filter(item => values.includes(item)).join(', ') :
                placeholder

              return displayValue
            }}
            MenuProps={MenuProps}
            endAdornment={
              allowExternalAccess ?
                <InputAdornment position="end" sx={{ margin: 0 }}>
                  {clientExternalIcon}
                </InputAdornment> : <></>
            }
          >
            <MenuItem
              disabled
              value=""
              sx={{
                whiteSpace: 'unset',
                wordBreak: 'break-all',
              }}
            >
              {placeholder}
            </MenuItem>
            {menuItems.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox checked={value?.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}

export default React.memo(CLASelect);