import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Grid
} from '@mui/material';
import { ClientExternalIcon } from '@ais/forms';
import { Flex } from '@ais/palette';
import styles from './CLACheckBoxGroup.module.css';

export const CLACheckBoxGroup = ({
  id,
  label,
  columns,
  options,
  allowOtherOption,
  value,
  onChange,
  isDisabled,
  onFocus,
  allowExternalAccess,
  answerable,
  answered,
  onBlur,
  onOtherFieldFocus,
  isIdle
}) => {
  const fieldId = 'CLACheckBoxGroup_' + id;

  const textFieldRef = useRef(null);
  const containerRef = useRef(null);

  const [actualValue, setActualValue] = useState([]);
  const [otherValue, setOtherValue] = useState('');
  const [otherToggled, setOtherToggled] = useState(false);

  const getOtherValue = (value) => {
    if (Array.isArray(value)) {
      const otherValue = value.map(val => val.toString())
                              .find((val) => options.indexOf(val) < 0);
      return otherValue;
    } else return null;
  };

  const getActualValues = (value) => {
    const actualValue = Array.isArray(value) ? 
                          value?.map(val => val.toString())
                                .filter(val => options.indexOf(val) >= 0) : 
                          [value.toString()]
    return actualValue;
  }
  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    let _actualValue = [];
    if (checked) { 
      const copy = [...actualValue];
      copy.push(value); 
      _actualValue = copy
      setActualValue(copy);
    } else {
      const filtered = actualValue.filter((e) => e !== value); 
      _actualValue = [...filtered] 
      setActualValue(filtered);
    } 
    onChange(_actualValue, otherValue, false);
  };

  const handleOtherCheckboxChange = (e) => {
    const checked = e.target.checked;
    let _otherValue = otherValue
    if (checked)
      textFieldRef.current?.focus();
    else {
      setOtherValue('')
      _otherValue = ''
    }
    setOtherToggled(checked);
    onChange(actualValue, _otherValue, checked);
  };

  const handleTextFieldChange = (e) => {
    const value = e.target.value;
    setActualValue((prevState) => {
      const copy = [...prevState];
      if (value) {
        if (!otherToggled) setOtherToggled(true);
        return copy;
      } else {
        const filtered = copy.filter((e) => e !== 'other');
        return [...filtered];
      }
    });
    setOtherValue(value);
  };
  
  const handleOtherTextBlur = (e) => {
    if(isIdle) return
    if (otherToggled && otherValue) {
      onChange(actualValue, otherValue, otherToggled, e)
    }
    onBlur && onBlur()
  }

  const handleOtherFieldFocus = () => {
    onFocus && onFocus();
    onOtherFieldFocus && onOtherFieldFocus();
  }

  const setupOptions = () => {
    const optionFields = options.map(
      (option, index) =>
        option && (
          <FormControlLabel
            key={index}
            name={option}
            control={
              <Checkbox
                checked={actualValue.includes(option)}
                onChange={handleCheckboxChange}
                disabled={!!isDisabled}
                onFocus={onFocus}
              />
            }
            value={option}
            label={option}
          />
        )
    );
    if (allowOtherOption) {
      const lastIndex = optionFields.length;
      optionFields.push(
        <div key={lastIndex} className={styles['other-wrapper']}>
          <FormControlLabel
            control={
              <Checkbox
                checked={otherToggled}
                onChange={handleOtherCheckboxChange}
                disabled={!!isDisabled}
                onFocus={onFocus}
                onBlur = {onBlur}
              />
            }
            label="Other"
          />
          <TextField
            inputRef={textFieldRef}
            ref={containerRef}
            value={otherValue}
            onChange={handleTextFieldChange}
            fullWidth
            disabled={!!isDisabled}
            onFocus={handleOtherFieldFocus}
            onBlur={handleOtherTextBlur}
          />
        </div>
      );
    }
    return optionFields;
  };

  useEffect(() => {
    if (!value) return;

    const actualValues = getActualValues(value);
    if (JSON.stringify(actualValues) !== JSON.stringify(actualValue)) {

      setActualValue(actualValues);
    }
    if (allowOtherOption) {
      const otherValue = getOtherValue(value);
      if (otherValue) {
        setOtherToggled(true);
        setOtherValue(otherValue);
      }else{
        setOtherToggled(false);
        setOtherValue('');
      }
    }
  }, [value]);

  useEffect(() => {
    if (isIdle && allowOtherOption) { 
      containerRef.current?.classList.remove('Mui-focused');
      textFieldRef.current?.blur();
      const actualValues = getActualValues(value);
      if (JSON.stringify(actualValues) !== JSON.stringify(actualValue)) { 
        setActualValue(actualValues);
      }
      const otherValue = getOtherValue(value); 
      if (otherValue) {
        setOtherToggled(true);
        setOtherValue(otherValue);
      } else {
        setOtherToggled(false);
        setOtherValue('');
      } 
    }
  }, [isIdle, value])
  
  return (
    <FormControl className={styles['checkbox-group']}>
      <Flex direction="row" justify="between">
        <FormLabel id={fieldId}>
          {label}
        </FormLabel>
        <ClientExternalIcon
          allowExternalAccess={allowExternalAccess}
          answerable={answerable}
          answered={answered} />
      </Flex>
      <FormGroup row name={fieldId} className={styles['container-list']}>
        <Grid container spacing={2} columns={columns}>
          {setupOptions().map((list, rowIndex) => (
            <Grid key={rowIndex} className={styles['list-wrapper']} item xs={1}>
              {list}
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

CLACheckBoxGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.array,
  columns: PropTypes.number,
  options: PropTypes.array,
  allowOtherOption: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  allowExternalAccess: PropTypes.bool,
  answerable: PropTypes.bool
};

CLACheckBoxGroup.defaultProps = {
  value: [],
  columns: 1,
  options: [],
  allowOtherOption: false,
  isDisabled: false,
  allowExternalAccess: false,
  answerable: false
};

export default CLACheckBoxGroup;