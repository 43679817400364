import { useState, memo } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateIcon } from "@ais/assets";
import { TextField } from '@mui/material';
import dayjs from 'dayjs'


const TableDatePicker = (props) => {
  const [errorState, setErrorState] = useState('');
  const dateIcon = () => {
    return (
      <img src={DateIcon} style={{ "marginRight": "5px" }} alt='CLA Date icon' />
    )
  }
  const handleOnChange = (date) => {
    let updatedDate = dayjs(date).format('MM/DD/YYYY');
    let year = dayjs(date).year();
    if (updatedDate !== 'Invalid Date' && year <= 2099) {
        props.onChange(updatedDate);
        setErrorState('');
    } else {
        updatedDate = null;
        setErrorState('Invalid Date Format');
    }
  }
  return(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        PopperProps={{
          placement: 'bottom-end'
        }}
        value={props.value ? props.value : null}
        defaultValue={props.defaultValue}
        onChange={handleOnChange}
        onOpen={props.onFocus}
        onClose={props.onBlur}
        components={{
          OpenPickerIcon: dateIcon
        }}
        disabled={props.disabled}
        renderInput={(inputProp)=>{
          return(
            <TextField
              {...inputProp}
              onFocus={props.onFocus}
              notched
              error={!!errorState}
              InputLabelProps={{
                shrink: true,
                variant: 'multiline-label'
              }}
              inputProps={{
                ...inputProp.inputProps,
              }}
              FormHelperTextProps={{
                sx: {
                  mx: 0
                }
              }}
              fullWidth
              onBlur={()=>{
                if(!errorState && props.value && props.onBlur){
                  props.onBlur()
                }
              }}
            />
          )
        }}
      />
    </LocalizationProvider>
  )
}
export default memo(TableDatePicker)