import React from 'react';
import { renderLabel } from "@ais/forms";
import CLASelect from './FieldComponents/CLASelect';
import CLADropdown from './FieldComponents/CLADropdown';
import CLAComboBox from './FieldComponents/CLAComboBox';
import CLAMultiOther from './FieldComponents/CLAMultiOther';


const DropDown = (props) => {
    const {
        field,
        disabled,
        clientExternalIcon,
        lockField,
        unlockField,
        answerList,
        onFocus,
        onSubmit,
        isQuestionGroupIdle
    } = props;
    const label = !!field.label ? renderLabel(field.label, field.tooltip) : null
    
    const renderDropdown = () => {
        if (field.allowOtherOption && field.allowMultiSelect) { 
            return (
                <CLAMultiOther
                    id={field.id}
                    name={field.id}
                    label={label}
                    isDisabled={disabled}
                    options={field.options}  
                    allowMultiLineLabel
                    placeholder={field.placeholder}
                    allowExternalAccess={field.visibleToClient ?? false}
                    clientExternalIcon={clientExternalIcon}
                    lockField={lockField}
                    unlockField={unlockField}
                    answerList={answerList}
                    onFocus={onFocus}
                    onSubmit={onSubmit}
                    isQuestionGroupIdle={isQuestionGroupIdle}
                />
            )
        } else if (field.allowOtherOption && !field.allowMultiSelect) {
            return (
                <CLAComboBox
                    id={field.id}
                    name={field.id}
                    label={label}
                    options={field.options}
                    allowMultiLineLabel
                    allowExternalAccess={field.visibleToClient ?? false}
                    isDisabled={disabled}
                    clientExternalIcon={clientExternalIcon}
                    placeholder={field.placeholder}
                    lockField={lockField}
                    unlockField={unlockField}
                    onFocus={onFocus}
                    onSubmit={onSubmit}
                />
            );
        } else if (!field.allowOtherOption && field.allowMultiSelect) {
            return (
                <CLASelect
                    id={field.id}
                    name={field.id}
                    label={label}
                    isDisabled={disabled}
                    menuItems={field.options}
                    allowExternalAccess={field.visibleToClient ?? false}
                    placeholder={field.placeholder}
                    clientExternalIcon={clientExternalIcon}
                    lockField={lockField}
                    unlockField={unlockField}
                    onFocus={onFocus}
                    onSubmit={onSubmit}
                />
            );
        } else {
            return (
                <CLADropdown
                    id={field.id}
                    name={field.id}
                    label={label}
                    menuItems={field.options}
                    isDisabled={disabled}
                    allowMultiLineLabel
                    allowExternalAccess={field.visibleToClient ?? false}
                    placeholder={field.placeholder}
                    clientExternalIcon={clientExternalIcon}
                    lockField={lockField}
                    unlockField={unlockField}
                    onFocus={onFocus}
                    onSubmit={onSubmit}
                />
            );
        }
    };

    return renderDropdown();
};

export default React.memo(DropDown);
