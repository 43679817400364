import React, { useState, useEffect } from 'react';
import {
    OutlinedInput,
    InputLabel,
    MenuItem,
    FormControl,
    InputAdornment,
    Select
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useRoomIdle } from '@ais/hooks';
import classes from './TbLinkSelect.module.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    selectIcon: {
        position: 'relative',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TbLinkDropdown = (props) => {
    const {
        menuItems,
        allowExternalAccess,
        label,
        id,
        error,
        placeholder = 'Select',
        name,
        isDisabled,
        clientExternalIcon,
        lockField,
        unlockField,
        onFocus,
        onChange,
        value
    } = props;
    const classesStyles = useStyles();
    const isIdle = useRoomIdle();
    const { control } = useFormContext();
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
        onFocus(null, id);
        lockField && lockField();
    }

    const handleClose = () => {
        setOpen(false);
        unlockField && unlockField();
    }

    const fieldId = `dropdown-${id}`;

    useEffect(() => {
        if (isIdle) setOpen(false);
    }, [isIdle]);

    const getSelectedMenuItemLabel = (value) => {
        return menuItems.filter(item => item.value === value)?.[0]?.label ?? placeholder;
    }

    return (
        <FormControl className={classes['tblink-select']} error={error} fullWidth>
            <InputLabel shrink id={id} variant="multiline-label">
                {label}
            </InputLabel>
            <Controller
                key={id}
                control={control}
                name={id}
                render={() => {
                    return (
                        <Select
                            labelId={`${fieldId}-label`}
                            id={fieldId}
                            classes={{ icon: classesStyles.selectIcon }}
                            sx={{ paddingRight: '4px' }}
                            disabled={isDisabled}
                            open={open}
                            name={name}
                            displayEmpty
                            value={value}
                            defaultValue={value}
                            test-id={fieldId}
                            test-value={value}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                onChange(value);
                            }}
                            onOpen={() => {
                                handleOpen()
                            }}
                            onClose={handleClose}
                            input={<OutlinedInput label={label} />}
                            renderValue={(selected) => {
                                return getSelectedMenuItemLabel(selected);
                            }}
                            MenuProps={MenuProps}
                            endAdornment={
                                allowExternalAccess &&
                                <InputAdornment position="end" sx={{ margin: 0 }}>
                                    {clientExternalIcon}
                                </InputAdornment>
                            }
                        >
                            <MenuItem
                                disabled
                                value=""
                                sx={{
                                    whiteSpace: 'unset',
                                    wordBreak: 'break-all',
                                }}
                            >
                                {placeholder}
                            </MenuItem>
                            {menuItems.map((option, index) => {
                                return (
                                    <MenuItem key={index} value={option.value}>
                                        {option?.hasOwnProperty('label') ? option.label : option}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    )
                }
                }
            />
        </FormControl>
    );
};

export default React.memo(TbLinkDropdown);
