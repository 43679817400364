export const SIGN_OPTIONS = {
    ACTUAL_SIGN: "Actual Sign",
    REVERSE_SIGN: "Reverse Sign"
}

export const TYPE_MENU_ITEMS = [
    {
        value: "account",
        label: "Type"
    },
    {
        value: "accountSubType",
        label: "Classification"
    },
    {
        value: "fsCaption",
        label: "Category"
    },
    {
        value: "accountName",
        label: "Sub Category"
    },
    {
        value: "glAccountNumber",
        label: "Account"
    }
]

export const FUND_LEVEL_MENU_ITEMS = [
    {
        value: "none",
        label: "None"
    },
    {
        value: "fundType",
        label: "Fund Type"
    },
    {
        value: "fundSubType",
        label: "Fund SubType"
    },
    {
        value: "fund",
        label: "Fund"
    }
]

export const GetAnswerFromTrialBalanceSupportingValue = (value) => {
    return IsAnswerTrialBalance(value) ? JSON.parse(value).amount ?? "No Data Available" : value ?? '';
}

export const GetCorrelationNameIdsFromTrialBalance = (value) => {
    return IsAnswerTrialBalance(value) ? JSON.parse(value).correlationNameIds : [];
}

export const GetTrialBalanceFromTrialBalanceSupportingValue = (value) => {
    return IsAnswerTrialBalance(value) ? JSON.parse(value) : {};
}

export const IsAnswerTrialBalance = (value) => {
    //To avoid excessive exceptions, check if the string value contains object-like syntax before attempting to parse it for validation
    //First if check to prevent attempts to trim undefined during load
    if (typeof value !== 'string') return false;
    if (value.trim().startsWith('{') && value.trim().endsWith('}')) {
        try {
            return Object.keys(JSON.parse(value)).includes('correlationDetailIds');
        } catch {
            return false;
        }
    } else {
        return false;
    };
}

export const FilterValidTrialBalances = (trialBalanceData) => {
    return trialBalanceData.filter(
        tb => !!tb.correlationDetailId && !!tb.correlationName && !!tb.correlationNameId
    );
}

export const FilterTrialBalances = (trialBalanceData) => {
    const uniqueTrialBalances = [...new Map(trialBalanceData.map(tb => [tb.correlationNameId, tb])).values()]
    const noNullsUniqueTrialBalances = uniqueTrialBalances.filter(
        tb => !!tb.correlationDetailId && !!tb.correlationName && !!tb.correlationNameId
    )
    return noNullsUniqueTrialBalances;
}

export const EvaluateFundIndexOptions = (value) => {
    switch (value) {
        case 'fund':
            return 'segment03';
        case 'fundType':
            return 'segment04';
        case 'fundSubType':
            return 'segment05';
        default:
            return '';
    }
}

export const ConstructAnswerPayload = (trialBalanceRawData, detailedTrialBalanceData) => {
    trialBalanceRawData.amount = CalculateAnswerSum(trialBalanceRawData, detailedTrialBalanceData);
    return trialBalanceRawData;
}

//FILTERS (Selected Trial Balances, Group/Account/Type, Period, Balance Type, (If Applicable Fund Level Info))
const CalculateAnswerSum = (trialBalanceRawData, detailedTrialBalanceData) => {
    //Dates for filtering logic only (will not be bound, Engine B dates are formatted differently)
    const [startDate, endDate] = trialBalanceRawData.period.replaceAll(' ', '').split('-').map(FormatTbPeriodDate);

    //This gets the trial balances selected in the dropdown and their variancy (TB 1 unadjusted, TB 1 final (which will have same correlationNameId since it's from same engagement file)
    //We then examine the dynamic filters the user selects, apply them, and then summate the final result
    const selectedTrialBalancesCorrelationNameIds = detailedTrialBalanceData.filter(tb => trialBalanceRawData.correlationNameIds.includes(tb.id)).map(tb => tb.correlationNameId);

    //Filter selected TBs, period and balance type for TB scope
    const selectedDetailedTrialBalances = detailedTrialBalanceData.filter(tb =>
        selectedTrialBalancesCorrelationNameIds.includes(tb.correlationNameId) &&
        tb.trialBalanceType === trialBalanceRawData.balanceType &&
        tb.startDate === startDate &&
        tb.endDate === endDate
    );

    //Apply dynamic filters for group/account type
    const selectedDetailedTrialBalancesGroups = selectedDetailedTrialBalances.flatMap(tb => tb.groups);
    const applicableSummationItems = [...selectedDetailedTrialBalancesGroups].filter(group =>
        group[trialBalanceRawData.type] === trialBalanceRawData.groupAccount
    );

    //If fund applies, apply fund level filter and summate, otherwise summate all applicable items
    if (trialBalanceRawData.fundIndex) {
        const targetKey = EvaluateFundIndexOptions(trialBalanceRawData.fundLevel);
        const applicableFundLevelItems = applicableSummationItems.filter(group => group[targetKey] === trialBalanceRawData.fundIndex);

        const sum = applicableFundLevelItems.length > 0 ? applicableFundLevelItems.map(group => group.amountEnding).reduce((sum, number) => sum + number) : 0;
        return trialBalanceRawData.signOptions === SIGN_OPTIONS.ACTUAL_SIGN ? sum : sum * -1;
    } else {
        const sum = applicableSummationItems.length > 0 ? applicableSummationItems.map(group => group.amountEnding).reduce((sum, number) => sum + number) : 0;
        return trialBalanceRawData.signOptions === SIGN_OPTIONS.ACTUAL_SIGN ? sum : sum * -1;
    }
}

//Format to match engine B format to avoid date parsing issues when filtering period data for summation
export const FormatTbPeriodDate = (date) => {
    const [month, day, year] = date.split('/');
    return `${year}-${month}-${day}`;
}