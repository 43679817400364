import { RESERVED_QUESTION_IDS } from "@ais/constants";

export const isCustomFormId = id => {
	const ids = [];

	for (const formType in RESERVED_QUESTION_IDS) {
		ids.push(...RESERVED_QUESTION_IDS[formType]);
	}

	return ids.includes(id);
}
