import {useMemo} from 'react';
import {styled} from '@mui/system';
import {useLocation} from 'react-router-dom';

import {Field, FieldV2} from '@components/ProjectForm';

const StyledRowField = styled('div')({
    display: 'flex',
    position: 'relative',
    flexFlow: 'row',
    flexWrap: 'wrap',
    width: '100%'
});

export const FieldBuilder = ({ fields, handleSubmit }) => {
    const {pathname} = useLocation(); //TEMPORARY. THIS SHOULD BE REMOVE ONCE V2 IS TESTED ALREADY!!!

    const handleBlur = () => {
        handleSubmit();
    };

    //TEMPORARY. THIS SHOULD BE REMOVE ONCE V2 IS TESTED ALREADY!!!
    const isV2 = useMemo(() => {
        return pathname.includes("v2/project/")
    }, [pathname]);

    return (
        <>
            { fields.map((element, idx) => {
                return (
                    <StyledRowField key={idx}>
                        { element.map((field, index) => {
                            if (!isV2)
                                return <Field key={index} handleOnBlur={handleBlur} field={field} index={index} />
                            return <FieldV2 key={index} field={field} handleSubmit={handleSubmit}/>
                        })}
                    </StyledRowField>
                )                
            })}
            
        </>
    )
}

export default FieldBuilder
