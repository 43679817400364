import {FIELD_TYPES} from "./formdesigner";

const {
	KPI_TRIAL_BALANCE,
	CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP,
	PERFORMANCE_STANDARDS,
	PROJECT_SCOPE_AUDIT_AREA,
	PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS,
	PROJECT_SCOPE_TRIAL_BALANCE,
	REPORTING_FRAMEWORKS,
	TRIAL_BALANCE,
	GENERAL_LEDGER,
	CURRENT_PERIOD_TO_PRIOR,
	TRENDING_BY_MONTH,
	KPIS_RATIOS,
	PURPOSE_OBJECTIVE_PROCEDURE,
	UNDERSTANDING_OF_ENTITY,
	COMMUNICATION_OF_SIGNIFICANT_MATTERS,
	EVALUATING_KEY_CONTROL,
	EVALUATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL,
	COMMUNICATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL,
	ACCOUNT_AND_ASSERTION_LEVEL_RISK,
	FINANCIAL_STATEMENT_LEVEL_RISK,
} = FIELD_TYPES;

export const RESERVED_SECTION_IDS = {
	SCOPING: {
		INITIAL_SCOPING_CONSIDERATIONS: 'ceef1d1b-da9f-43ae-9082-9d7c274860bf',
		DETERMINING_SCOTABDS: 'e1980ed6-deed-4376-9fbb-267885e91ab1',
		COMMON_IDENTIFIED_RISKS: '94b77adb-0f0e-46ae-8549-0205e8bd29c5'
	},
	RISK_SUMMARY: {
		FINANCIAL_STATEMENT_LEVEL_RISK: '5ccb0b4d-e07a-4bb5-9139-cd3f7a3a833d',
		ASSERTION_LEVEL_RISK: '99629dfb-bd48-4fe9-980d-9f6e67f0e1cd'
	},
	ANALYTICS: {
		GENERAL_LEDGER: '19076e8c-3ce9-4799-8109-27cb63755e45',
		CURRENT_PERIOD: '22190952-a797-4a01-8fda-b1e59f79add2',
		TRENDING_BY_MONTH: 'a6a151c5-64ae-4709-8cca-ba7fd28050e3'
	}
};

export const RESERVED_QUESTION_IDS = {
  KPI: ['64aae4ef-3e4d-4feb-934b-f8537c1a1d6e'],
	SCOPING: [
		'00000000-0000-0000-0000-000000000001', //TEST_OPERATING_EFFECTIVENESS
		'00000000-0000-0000-0000-000000000002', //PERFORMANCE_STANDARDS
		'00000000-0000-0000-0000-000000000003', //REPORTING_FRAMEWORKS
		'a6a64459-92b1-4003-8cf7-4519d69d3bc6', //PROJECT_SCOPE_AUDIT_AREA
		'5ab229e8-0af1-4624-ae7d-b2d823f8b717', //PROJECT_SCOPE_TRIAL_BALANCE
		'4a78af81-0e4f-4834-a44e-55b113c6c620', //CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP
	],
	PLANNING: [
		'28583e76-f2a5-41c7-9526-bfaca82c5a41'
	]
};

export const CUSTOM_FORM_FIELD_TYPES = {
	DEFICIENCY: [EVALUATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL, COMMUNICATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL],
	ICF: [
		PURPOSE_OBJECTIVE_PROCEDURE,
		UNDERSTANDING_OF_ENTITY,
		COMMUNICATION_OF_SIGNIFICANT_MATTERS,
		EVALUATING_KEY_CONTROL
	],
	KPI: [KPI_TRIAL_BALANCE],
	PLANNING: [
			TRIAL_BALANCE,
			GENERAL_LEDGER,
			CURRENT_PERIOD_TO_PRIOR,
			TRENDING_BY_MONTH,
			KPIS_RATIOS
		],
	RISK_SUMMARY: [ACCOUNT_AND_ASSERTION_LEVEL_RISK, FINANCIAL_STATEMENT_LEVEL_RISK],
	SCOPING: [
			CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP,
			PERFORMANCE_STANDARDS,
			PROJECT_SCOPE_AUDIT_AREA,
			PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS,
			PROJECT_SCOPE_TRIAL_BALANCE,
			REPORTING_FRAMEWORKS
		],
};